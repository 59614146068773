<template>
    <div class="p-3">
        <form v-on:submit="formSubmit" :class="(validated ? 'was-validated ': '') +'needs-validation'" novalidate>
            <div class="form-group">
                <div class="row">
                    <label for="participant-id" class="col-sm-2 col-form-label">{{ gettext("Participant ID:") }}</label>
                    <div class="col-sm-10">
                        <input type="text" autocomplete="off" class="form-control" name="participant-id" id="participant-id" v-model="participant_id" aria-describedby="participant_help" oninput="let p=this.selectionStart;this.value=this.value.toUpperCase();this.setSelectionRange(p, p);">
                        <small class="form-text text-muted text-left px-2" id="participant_help">{{ gettext("If you've been given a Participant ID please enter it, otherwise leave blank") }}</small>
                    </div>
                </div>
            </div>

            <div class="form-group row">
                <label for="age" class="col-sm-2 col-form-label">{{ gettext("Age:") }}</label>
                <div class="col-sm-10">
                    <input type="number"
                           class="form-control"
                           name="age"
                           id="age"
                           aria-describedby="age_help"
                           v-model="age">
                    <small class="form-text text-muted text-left px-2" id="age_help">{{ gettext("Enter your age in terms of years") }}</small>
                    <div class="invalid-feedback">
                        {{ gettext(errors.age) }}
                    </div>
                </div>
            </div>

            <div class="form-group row">
                <label for="education" class="col-sm-2 col-form-label">{{ gettext("Education:") }}</label>
                <div class="col-sm-10">
                    <input type="number"
                           class="form-control"
                           id="education"
                           aria-describedby="education_help"
                           v-model="education">
                    <small class="form-text text-muted text-left px-2" id="education_help">{{ gettext("Enter number of years of schooling completed starting with primary school") }}</small>
                    <div class="invalid-feedback">
                        {{ gettext(errors.education) }}
                    </div>
                </div>
            </div>

            <div class="form-group row">
                <label for="sex" class="col-sm-2 col-form-label">{{ gettext("Sex:") }}</label>
                <div class="col-sm-10">
                    <select class="custom-select" id="sex" v-on:change="updateSex">
                        <option :value="'male'">{{ gettext("Male") }}</option>
                        <option :value="'female'">{{ gettext("Female") }}</option>
                        <option :value="'Unknown'">{{ gettext("Prefer not to say") }}</option>
                    </select>
                </div>
            </div>

            <CountrySelect v-if="ask_country" :errors="errors.country" v-on:country-selection="updateCountry"></CountrySelect>

            <div v-if="ask_ethnicity" class="form-group row">
                <label for="ethnicity" class="col-sm-2 col-form-label">{{ gettext("Ethnicity:") }}</label>
                <div class="col-sm-10">
                    <select class="custom-select" id="ethnicity" v-on:change="updateEthnicity">
                        <option :value="''" selected disabled>{{ gettext('Please Select') }}</option>
                        <option :value="'American Indian or Alaska Native'">{{gettext("American Indian or Alaska Native")}}</option>
                        <option :value="'Asian'">{{ gettext("Asian") }}</option>
                        <option :value="'Black or African American'">{{ gettext("Black or African American") }}</option>
                        <option :value="'Hispanic or Latino'">{{ gettext("Hispanic or Latino") }}</option>
                        <option :value="'Native Hawaiian or Other Pacific Islander'">{{ gettext("Native Hawaiian or Other Pacific Islander") }}</option>
                        <option :value="'White'">{{ gettext("White") }}</option>
                        <option :value="'Other'">{{ gettext("Other") }}</option>
                    </select>
                    <div class="invalid-feedback">
                        {{ gettext(errors.ethnicity) }}
                    </div>
                </div>
            </div>

            <button type="submit" class="btn btn-lg btn-primary my-2">{{ gettext('OK') }}</button>
        </form>
        <template v-if="getting_id">
            <p>{{ gettext("Generating Participant ID, you will be redirected shortly!") }}</p>
        </template>
    </div>
</template>

<script>
    import "whatwg-fetch";
    import CountrySelect from "./CountrySelect";

    export default {
        name: "UserInformation",
        components: {CountrySelect},
        props: ['base_url', 'endpoint_version', 'ask_country', 'ask_ethnicity'],

        mounted() {
            this.age_input = document.getElementById("age");
            this.education_input = document.getElementById(("education"));
            if(this.ask_country) {
                this.country_select = document.getElementById("country");
                this.country = this.country_select.value;
            }
            if(this.ask_ethnicity) {
                this.ethnicity_select = document.getElementById("ethnicity");
                this.ethnicity = this.country_select.value;
            }
        },

        data: function() {
            return {
                participant_id: null,
                age: null,
                education: null,
                sex: 'male',
                country: "Not Requested",
                ethnicity: "Not Requested",
                errors: {},
                validated: false,
                age_input: null,
                education_input: null,
                country_select: null,
                ethnicity_select: null,
                getting_id: false
            }
        },
        methods: {
            validateAge: function(event) {
                if(this.age !== null && this.age !== "" && this.age > 0 && this.age <= 120 && this.age%1 === 0) {
                    this.age_input.setCustomValidity("");
                    return true;
                }
                else {
                    this.errors.age = this.gettext("Age must be a valid number between 1-120 (No decimals)");
                    this.age_input.setCustomValidity(this.errors.age);
                    return false
                }

            },

            validateEducation: function(event) {
                if(this.education !== null && this.education !== "" && this.education >= 0 && this.education <= 21 && this.education %1 === 0) {
                    this.education_input.setCustomValidity("");
                    return true
                }
                else {
                    this.errors.education = this.gettext("Education must be a valid number between 0-21 (No decimals)");
                    this.education_input.setCustomValidity(this.errors.education);
                    return false
                }
            },

            validateParticipantId: async function(event) {
                if(this.participant_id === null || this.participant_id === "") {
                    this.getting_id = true;
                    this.participant_id = await this.retrieveParticipantId()
                }
                return true;
            },

            validateCountry: function() {
                if(!this.ask_country || (this.ask_country && this.country !== null && this.country !== ""))
                {
                    if(this.ask_country) {
                        this.country_select.setCustomValidity("");
                    }
                    return true
                }
                else {
                    this.errors.country = this.gettext("Please make a selection");
                    this.country_select.setCustomValidity(this.errors.country_select);
                    return false
                }
            },

            validateEthnicity: function() {
                if(!this.ask_ethnicity || (this.ask_ethnicity && this.ethnicity !== null && this.ethnicity !== "")) {
                    if(this.ask_ethnicity) {
                        this.ethnicity_select.setCustomValidity("");
                    }
                    return true;
                }
                else {
                    this.errors.ethnicity = "Please make a selection";
                    this.ethnicity_select.setCustomValidity(this.errors.ethnicity);
                    return false;
                }
            },


            retrieveParticipantId: async function(event) {
                return await window.fetch(`${this.base_url}endpoints/${this.endpoint_version}/generate-participant-id`)
                .then((data) => {
                   return data.json();
                })
                .then((json) => {
                    this.getting_id = false;
                    return json.participant_id
                })
                .catch((err) => {
                    console.log(err);
                });
            },

            formValidation: async function(event) {
                // Single & since we want to run the validation on education even if age is incorrect
                // This sets the custom invalid error messages.
                // We do not want to run the participantID validation if either of the validations prior are false (it requests an ID from the endpoint)

                return this.validateAge() & this.validateEducation() & this.validateCountry() & this.validateEthnicity() && await this.validateParticipantId();
            },

            formSubmit: async function(event) {
                event.preventDefault();
                let valid = await this.formValidation();
                this.validated = true;
                if(valid && !this.getting_id) {
                    this.$emit('submission', this.participant_id, this.age, this.education, this.sex, this.country, this.ethnicity);
                }

            },

            updateSex: function(event) {
                this.sex = event.target.value;
            },

            updateCountry: function(country_val) {
                this.country = country_val;
            },

            updateEthnicity: function(event) {
                this.ethnicity = event.target.value;
            }

        }
    }
</script>

<style scoped>

</style>