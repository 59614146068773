<template>
    <font-awesome-icon icon="fa-solid fa-spinner" spin :size="size"/>
</template>

<script>
export default {
  name: 'LoadingSpinner',
  props: {
    size: {
      type: String,
      default: "4x"
    }
  }
}
</script>

<style>

</style>
