<template>
    <div id="app">
        <div class="app-container px-0 pb-lg-3 pb-sm-0 mx-sm-0 mx-lg-auto my-lg-5 my-sm-0">
            <template v-if="test_loading">
                <h1 class="mb-3">{{ gettext("Loading...") }}</h1>
                <LoadingSpinner size="2x"/>
            </template>
            <template v-else>
                <div v-if="show_header" :class="end_text_class !== '' && step !== 0 ? `${end_text_class} app-header` : 'app-header'">
                    <h4>{{ gettext("Multicultural Alzheimer's Prevention Program") }}</h4>
                </div>
                <template v-if="endpoint === null">
                    <div class="p-3">
                      <h5 class="text-left">The MAPP Remote tests were authored by Yakeel T. Quiroz PhD, Clara Vila-Castelar PhD, Ana Baena MA, Joshua Fox-Fuller PhD, Paula Aduen PhD, and Averi Giudicessi MA © Multicultural Alzheimer’s Prevention Program, Massachusetts General Hospital, 2020</h5>
                      <h6>Which test would you like to take?</h6>
                      <div class="my-2">
                        <a v-if="production_env" class="btn btn-primary my-1" href="https://www.mappremote.com/s/objectsv2/language/select/">Mesero Test</a>
                        <a v-else class="btn btn-primary my-1" href="https://dev.mappremote.com/s/MESERO1/language/select/">Mesero Test</a>

                      </div>
                      <div class="my-2">
                          <a v-if="production_env" class="btn btn-primary my-1" href="https://www.mappremote.com/r/Roomtestv2/language/select/">Room Test</a>
                          <a v-else class="btn btn-primary my-1" href="https://dev.mappremote.com/r/rt2/language/select/">Room Test</a>

                      </div>
                      <div class="my-2">
                        <a v-if="production_env" class="btn btn-primary my-1" href="https://www.mappremote.com/f/fname/language/select/">Face Name Test</a>
                        <a v-else class="btn btn-primary my-1" href="https://dev.mappremote.com/f/Fname-test/language/select/">Face Name Test</a>
                      </div>
                    </div>
                </template>
                <template v-else>
                    <template v-if="step === 0">
                        <UserInformation :base_url="base_url"
                                         :endpoint_version="endpoint_version"
                                         :ask_country="test_json.ask_country"
                                         :ask_ethnicity="test_json.ask_ethnicity"
                                         v-on:submission="userInfo"></UserInformation>
                    </template>

                    <template v-else-if="step === 1">
                        <template v-if="test_type === 'r'">
                            <RoomDriver :json_data="test_json"
                                        v-on:submission="handleSubmission"
                                        v-on:complete="driverComplete"></RoomDriver>
                        </template>
                        <template v-else-if="test_type === 's'">
                            <SeriesDriver :json_data="test_json"
                                          v-on:submission="handleSubmission"
                                          v-on:complete="driverComplete"></SeriesDriver>
                        </template>
                        <template v-else-if="test_type === 'f'">
                            <FNameDriver :json_data="test_json"
                                         v-on:submission="handleSubmission"
                                         v-on:complete="driverComplete"
                                         :is_mobile="is_mobile"></FNameDriver>
                        </template>
                        <template v-else>
                            <h1 class="text-align-center">{{ gettext("Could not find this type of test, please contact a MAPP administrator") }}</h1>
                        </template>
                    </template>

                    <template v-else-if="step === 2">
                      <div class="my-4">
                        <template v-if="!can_close">
                              <LoadingSpinner/>
                        </template>
                        <template v-else>
                              <p :class="end_text_class">{{ gettext(this.end_message)}}</p>
                              <p :class="end_text_class">{{ gettext("Your results have been recorded! You may now close this window.") }}</p>
                        </template>
                      </div>
                    </template>
                </template>
            </template>
        </div>
    </div>
</template>

<script>
import RoomDriver from "./components/RoomDriver";
import SeriesDriver from "./components/SeriesDriver";
import UserInformation from "./components/UserInformation";

import "whatwg-fetch"
import timing from "./timing";
import FNameDriver from "@/components/fname/FNameDriver";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

export default {
    name: 'App',
    mixins: [timing],
    components: {
      LoadingSpinner,
      FNameDriver,
        UserInformation,
        SeriesDriver,
        RoomDriver,
    },
    mounted: function() {
        let protocol = window.location.protocol;
        let host = window.location.host;
        this.base_url = `${protocol}//${host}/`;
        let uri = window.location.pathname;
        let split_uri = uri.split("/");
        if(split_uri.length < 4) {
            // Incorrect url such as https://www.dev.mappremote.com
            this.endpoint = null;
            this.test_loading = false;
            this.production_env = (host === "www.mappremote.com")
            // Don't execute the rest of this function
            return;
        }
        else if (split_uri.length === 4) {
            this.test_type = split_uri[1];
            this.test_name = split_uri[2];
        }
        else if(split_uri.length > 4) {
            this.language = split_uri[1];
            this.test_type = split_uri[2];
            this.test_name = split_uri[3];
        }
        this.endpoint = `${this.base_url}endpoints/${this.endpoint_version}/tests/${this.test_type}/${this.test_name}`;
        window.fetch(this.endpoint)
        .then((data) =>{
            return data.json();
        })
        .then((json) => {
            this.test_json = json;
            this.test_loading = false;
        })
        .catch((error) => {
            console.log(error);
        });

        if(this.test_type === 'r') {
            this.end_message = this.gettext("Thank you for taking the room test.");
        }
        else if (this.test_type === 's') {
            this.end_text_class = "series-app"
            this.end_message = this.gettext("Thank you for taking the series test.");
        }
        else if(this.test_type === 'f') {
          this.end_message = this.gettext("Thank you for taking the Face-Name test.");
        }
        let smaller_dimension = screen.width < screen.height ? screen.width : screen.height
        this.is_mobile = smaller_dimension < 760
    },

    data: function () {
        return {
            time_start: 0,
            tme_end: 0,
            is_mobile: false,
            endpoint_version: "v1",
            base_url: null,
            step: 0,
            language: "en",
            participant_id: null,
            age: 0,
            education: 0,
            sex: null,
            country: null,
            ethnicity: null,
            query_parameters: null,
            test_type: "r",
            test_name: null,
            endpoint: null,
            is_complete: false,
            show_header: true,
            test_json: {},
            test_loading: true,
            results: {},
            end_message: "",
            end_text_class: "",
            can_close: false,
            production_env: false,
        }
    },
    methods: {
        incrementStep: function() {
            this.step++;
            if(this.step === 1) {
                this.show_header = false;
                this.recordStartTime();
                if(this.test_type === 's') {
                document.body.className += " series-app";
                this.show_header = false;
                }
                else {
                    document.body.className = "";
                }
            }
            else {
                this.show_header = true;
                this.recordEndTime();
            }
        },
        decrementStep: function() {
            this.step--;
        },
        setStep: function(step_num) {
            this.step = step_num;
        },

        userInfo: function(participant_id, age, education, sex, country, ethnicity) {
            this.participant_id = participant_id
            this.age = parseInt(age, 10)
            this.education = parseInt(education, 10)
            this.sex = sex
            this.country = country
            this.ethnicity = ethnicity
            this.incrementStep()
        },

        driverComplete: function() {
            this.is_complete = true
            // Update the value in the object we POST
            this.results.is_complete = true
            this.incrementStep()
            // Post one final time to ensure the "is_complete" flag is set
            this.$nextTick(() => {
                let postResult = this.postData(`${this.base_url}${this.language}/endpoints/${this.endpoint_version}/submit/`, this.results)
            })
        },

        handleSubmission: function(driverResults, driverEvent="UNKNOWN", is_complete=false) {
          // Only update is_complete if it isn't true already.
            this.is_complete = this.is_complete ? this.is_complete : is_complete
            this.results = this.createResults(driverResults, driverEvent);
            let postResult = this.postData(`${this.base_url}${this.language}/endpoints/${this.endpoint_version}/submit/`, this.results);
        },

        postData: async function(url, data) {
            this.can_close = false
            const response = await window.fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            this.can_close = true
            return response
        },

        createResults: function(driverResults, driverEvent) {
            return {
                test_id: driverResults.test_id,
                test_type: driverResults.test_type,
                dt_started: this.time_start,
                dt_finished: this.time_end,
                is_complete: this.is_complete,
                participant_id: this.participant_id,
                user_agent: navigator.userAgent,
                age: this.age,
                sex: this.sex,
                education: this.education,
                language: this.language,
                country: this.country,
                ethnicity: this.ethnicity,
                user_results: driverResults.user_results,
                groups_obj: driverResults.groups_obj,
                averages: driverResults.averages,
                scores: driverResults.scores,
                task_scores: driverResults.task_scores,
                // used to determine which event was used to POST
                frontend_driver_event: driverEvent,
                is_mobile: this.is_mobile
            }
        }
    }

}
</script>

<style>
@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";


#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}


</style>
